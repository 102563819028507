/* Input / Input Group */

.input-groups { 
	margin-top:15px; 
}
/*
.input-groups label { 

}

.input-groups input { 
	margin-top:15px; 
}

.input-groups #cke_form_body { 
	margin-top:15px; 
}

.input-groups select { 
	width:100%; 
}

.input-groups select option { 
	width:100%; 
}

/* Form */
input:not(input[type=file]) { 
	width:100%; 
	height:44px; 
	background-color:white; 
	border:1px solid #eaeaea; 
	display:block; 
	padding-left:15px; 
}

input[type=file]{ width: 100%; padding-left: 15px }

select { 
	width:100%; 
	height:44px; 
	background-color:white; 
	border:1px solid #eaeaea; 
	display:block; 
	padding-left:15px; 
}
/*
.btn-save { 
	background-color:white; 
	border:2px solid #cff1ea; 
	border-radius:5px; 
	padding:10px 16px; 
	font-size:13px; 
	cursor:pointer; 
	transition:0.2s; 
}

.btn-save:hover { 
	background-color:#cff1ea; 
	transition:0.2s;  
}*/

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type="number"] {
    -moz-appearance: textfield;
}