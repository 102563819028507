@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.5/css/select2.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@import "assets/css/back/components/bootstrap/dist/css/bootstrap.min.css";
@import "assets/css/back/components/font-awesome/css/font-awesome.min.css";
@import "assets/css/back/components/Ionicons/css/ionicons.min.css";
@import "assets/css/back/adminlte/AdminLTE.min.css";
@import "assets/css/back/adminlte/skins/_all-skins.min.css";
@import "assets/css/back/components/morris.js/morris.css";
@import "assets/css/back/components/jvectormap/jquery-jvectormap.css";
@import "assets/css/back/components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "assets/css/back/components/bootstrap-daterangepicker/daterangepicker.css";
@import "assets/css/back/components/fullcalendar/dist/fullcalendar.min.css";
@import "assets/css/back/components/fullcalendar/dist/fullcalendar.print.min.css";

@import "assets/css/back/components/grid.scss";
@import "assets/css/back/components/table.scss";
@import "assets/css/back/components/form.scss";
@import "assets/css/back/components/button.scss";

//responsive
@import "assets/css/responsive.scss";


//VARIABLES
:root {
	--blue: #0073bd;
	--light-blue: #dae9f2;

	--grey: #f6f6f6;
	--dark-grey: #7f7f7f;
	--black: #424343;

	--green: #6dbf3c;
	--dark-green: #519529;
	--light-green: #d4f5c0;

	--red: #ff5e5e;
	--dark-red: #cd0202;
	--light-red: #ffa0a0;

	--yellow: #e6d307;
	--dark-yellow: #a47e00;
	--light-yellow: #efe9ab;

	--base-font-size: 14px;
	--h1-font-size: 25px;
	--h2-font-size: 20px;
}


h1 { font-size: var(--h1-font-size) !important }
h2 { font-size: var(--h2-font-size) !important }



.testform::before {
	content: "";
	position: absolute;
	pointer-events: none;
}
.testform::after {
	content: "";
	position: absolute;
	pointer-events: none;
}

/* Général Class */
html, body { 
	font-family: Lato !important;
	font-size: var(--base-font-size);
	min-height: 100vh !important;
}

body #root {
	min-height: 100vh !important;
}

ul { 
	list-style-type: none; 
}

a { 
	text-decoration: none; 
}

a:hover { 
	text-decoration: none; 
}

.box-info {
	padding:15px;
}


.main-content { 
	transition:0.2s;
}

.main-content .content {
	margin-left:250px;
	height:100vh;
	padding:30px;
	padding-top:95px;
}

.content .row.pad {
	padding-left: 15px;
	margin-left: 15px;
}

.block-white {  
	background-color:white;
	padding:5px 20px;
	box-shadow: 0 2px 12px -3px rgba(0,0,0,.5);
	border-radius: 4px;
	margin-bottom:30px;
}

.content-header {
	padding: 15px 15px 5px 15px !important;
}

.content-header > h1 {
	font-size: var(--h1-font-size) !important;
	font-weight: 900;
	color: var(--black);
	font-family: Lato !important;
}
.content-header > h1 > small {
    font-size: 15px;
    display: inline-block;
	margin-left: 10px;
    padding-left: 4px;
    font-weight: 300;
}
.content-header > .breadcrumb > li > a {
    text-decoration: none;
    display: inline-block;
    color: #444 !important;
}


h1.title-page {
	font-size:18px;
	font-weight:400;
	margin-top:10px;
}

h1.title-page i {
	width:45px;
	height:45px;
	box-shadow: 0 2px 12px -3px rgba(0,0,0,.5);
	background-color: #7a3fbc;
	color:white;
	margin:0 auto;
	text-align: center;
	border-radius: 5px;
	line-height: 40px;
	font-size:24px;
	margin-right:15px;
}

input[type="checkbox"], input[type="radio"] {
	display: inline-block !important;
	width: auto !important;
	height: auto !important;
}


/*
.main-content .nav-top { height:70px; width:100%; background-color:white; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; }
.main-content .fil-ar { height:70px; width:100%; background-color:white; margin-top:15px; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; }
.main-content .fil-ar input { width:300px; float:left; margin-top:13px; margin-right:20px; }
.main-content .fil-ar .btn-add { background-color:#26a69a; text-align: center; padding: 10px 16px; color:white; transition:0.2s; border-radius: 5px; font-size:14px; letter-spacing: .5px; text-transform: uppercase; float:right; margin:15px 15px 0 0; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2); }
.main-content .fil-ar .btn-add:hover { background-color:#1a9a8e;  transition:0.2s; }
.main-content .fil-ar .title-page { float:left; margin:21px 20px 0 20px; }
*/
.block-plugins { text-align: center; }
.block-plugins h2 { margin-top:15px; text-transform: uppercase; }
.block-plugins img { margin-top:15px; }
.block-plugins p { margin-top:15px; min-height:80px; font-size:14px; }
/*
.options { width:100%; background-color:white; margin-top:15px; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; padding:15px; }
.options .block-option { display:flex; margin-top:15px; }
.options .block-option .switch { position: relative; display: inline-block; width: 41px; height: 21px; }
.options .block-option .switch input { opacity: 0; width: 0; height: 0; }
.options .block-option .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s; }
.options .block-option .slider:before { position: absolute; content: ""; height: 13px; width: 13px; left: 4px; bottom: 4px; background-color: white; -webkit-transition: .4s; transition: .4s; }
.options .block-option input:checked + .slider { background-color: #2196F3; }
.options .block-option input:focus + .slider { box-shadow: 0 0 1px #2196F3; }
.options .block-option input:checked + .slider:before { -webkit-transform: translateX(18px); -ms-transform: translateX(18px); transform: translateX(18px); }
.options .block-option .slider.round { border-radius: 34px; }
.options .block-option .slider.round:before { border-radius: 50%; } 
*/


.og-apercu {
	margin: 15px 0 30px 25px;
	line-height: 1.54;
	font-family: arial, sans-serif;
}

.og-apercu.block-white { padding: 20px 25px; }

.og-title {
	color: rgb(26, 13, 171);
	font-size: 18px;
	line-height: 1.33;
}

.og-url {
	padding: 2px 0 1px 0;
	line-height: 1.43;
	font-size: 14px;
	color: #006621;
}

.og-desc {
	color: #545454;
	word-wrap: break-word;
}

.og-date { color: #777; }

.og-apercu em {
	font-weight: bold;
	font-style: normal;
}
.invalide{
	padding: 5px;
	margin-left: -10px;
	margin-right: 5px;
	background-color: var(--light-blue);
	color: var(--blue);
	border-radius: 10px;
}
.box {
	box-shadow: none !important;
	border-top: 2px solid #d2d6de;
	border-radius: 2px;
}

.content-wrapper {
	background-color: white !important;
	min-height: 100vh !important;
}

.small-box sup {
	font-size: 20px;
}

.box.box-info {
	border-top-color: #783fbd !important;
}

.btn {
	border-radius: 5px !important;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
	border: 1px solid transparent !important;
	padding: 10px 16px !important;
	font-weight: 500 !important;
}
#login-section {
    background: #d2d6de;
	min-height: 100vh;
	.login-box{
		form {
			display: flex;
			display: -webkit-flex;
			flex-direction: column;
			-webkit-flex-direction: column;
			.input-group {
				margin-bottom: 15px;
				input {
					display: block;
					width: 100%;
					height: 34px;
					padding: 6px 12px;
					font-size: 14px;
					line-height: 1.42857143;
					color: #111;
					background-color: #fff;
					background-image: none;
					border: 1px solid #ccc  !important;;
						border-top-color: rgb(204, 204, 204) !important;;
						border-right-color: rgb(204, 204, 204) !important;;
						border-bottom-color: rgb(204, 204, 204) !important;;
						border-left-color: rgb(204, 204, 204) !important;;
					border-radius: 0 !important;;
					-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
					box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
					-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
					-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
					transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
				}
			}
			.btn.btn-primary.btn-block.btn-flat {
				border-radius: 0 !important;
				box-shadow: none !important;
				border-width: 1px !important;
				text-transform: capitalize !important;
				padding: 6px 0 !important;
				font-size: 14px;
			}
			.spinner-loader {
				margin: auto;
			}
		}
		.link_password{
		  color: #3c8dbc;
		  cursor: pointer
		}
	}
}
.box-body.account {
    padding: 0;
}
.box.tab-entete.TypesDePDS div.alert-info.alert {
    background-color: #00c0ef !important;
}
.content li + li {
    margin-top: 0 !important;
}
.btn-default {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
	border-radius: 0 !important;
	text-transform: none !important;
}
.NomOrder i{
	margin-right: 2px;
	font-size: 20px;
	line-height: 1;
}
.content-header {
	.breadcrumb {
		a {
			color: #444 !important;
			text-decoration: none !important;
			display: inline-block !important;
		}
	}
}
.skin-blue.sidebar-mini.sidebar-collapse {
	.logo-mini {
		display: block !important;
	}
	.logo-lg {
		display: none !important;
	}
}
.box.TypesDePDS_second {
	padding: 0px;
	border-top-color: #3c8dbc !important;
	border-width: 3px !important;
	border-top-style: solid !important;
	.holder {
		ul{
			list-style: none;
			margin-left: 0;
			margin-top: 0 !important;
			li {
				border-top: 1px solid #f4f4f4;
				padding: 8px;
				line-height: 1.42857143;
				vertical-align: top;
				font-size: 14px;
				line-height: 1.42857143;
				color: #333;
				list-style-type: none;
				margin: 0;
				&:hover{
					background-color: #f5f5f5;
				}
				&:nth-child(2n) {
					background-color: #f5f5f5;
				}
				input{
					width: calc(100% - 60px);
					margin-left: 60px;
				}
				div {
					padding-left: 60px;
				}
			}
		}
	}
	.btn.btn-default {
		background-color: #f4f4f4;
		color: #444;
		text-transform: none;
		border-color: #ddd;
		border-radius: 0 !important;
		box-shadow: none !important;
		border: 1px solid #ddd !important;
		&:hover {
			color: #333!important;
			background-color: #e6e6e6!important;
			border-color: #adadad!important;
			background-color: #e7e7e7!important;
		}
	}
	.holder ul {
		list-style: disc outside;
		margin-left: 0;
		margin-top: 1em;
	}
	.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc !important;
	border-radius: 0 !important;
		&:focus{
			border: 1px solid #3c8dbc !important;
			box-shadow: none;
		}
	}
}
.box.TypesDePDS_second.specialite 
{
	ul {
		li {
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			div{
				padding-left: 0;
				text-align: left;
				display: block;
				width: calc(100% / 3);
				padding: 0 8px !important;
			}
			input {
				width: calc(100%);
				margin-left: 0;
			}
		}
	} 
}
.box.tab-entete.TypesDePDS {
    padding: 0;
}
.box.tab-entete.TypesDePDS .alert-info.alert p, .alert-info.alert h4 {     
	color: white !important; 
	text-transform: none;
}
.box.tab-entete.TypesDePDS .pull-right {
    float: none !important;
    width: 100%;
    text-align: right;
    display: block;
}
#password, #passwordConfirm {
    border: 1px solid #ccc !important;
    border-radius: 0 !important;
}
.content-header > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    border-radius: 2px;
    display: flex;
    display: -webkit-flex;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: 54px;
}
.main-header .navbar-custom-menu, .main-header .navbar-right {
    float: right;
    position: absolute;
    right: 0;
}
.navbar-nav {
	.user-menu{
		.user_a_dropdown{
			min-width: 100px;
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			align-items: baseline;
			-webkit-align-items: baseline;
		}
		.dropdown-menu{
			li.user-header {
				height: 100px !important;
				padding: 10px;
				text-align: center;
			}
			.user-footer{
				background-color: #f9f9f9;
				padding: 10px;
				display: flex;
				display: -webkit-flex;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				height: 54px;
				.pull-left{
					text-align: left;
					float:none !important;
					width: calc(50% - 10px);
				}
				.pull-right{
					text-align: right;
					float:none !important;
					width: calc(50% - 10px);
				}
				a.btn.btn-default.btn-flat{
					box-shadow: none !important;
					border: 1px solid transparent !important;
					padding: 6px 12px !important;
					background-color: #f4f4f4;
					color: #444;
					border-color: #ddd !important;
					border-radius: 0 !important;
					text-transform: none;
					&:hover{
						border-color: #adadad !important;
						background-color: #e7e7e7 !important;
					}
				}
			}
		}
	}

	.country-menu {
		.country_a_dropdown{
			min-width: 100px;
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			align-items: baseline;
			-webkit-align-items: baseline;
		}
		.dropdown-menu {
			width: 280px;
			padding: 0 0 0 0;
			margin: 0;
			top: 100%;
			list-style: none;
			font-size: 14px;
			text-align: left;

			.menu {
				max-height: 200px;
				margin: 0;
				padding: 0;
				list-style: none;
				overflow-x: hidden;

				li {
					padding: 10px 10px;
					border-bottom: 1px solid #f4f4f4;
					cursor: pointer;

					&:hover {
						background-color: var(--grey);
					}
				}

				span {
					padding: 0;
					margin: 0 0 0 45px;
					color: var(--black);
					position: relative;
				}
			}

			.footer {
				padding: 7px 10px;
				text-align: center;
				font-size: 12px;

				span {
					cursor: pointer;
					&:hover {
						color: var(--blue);
					}
				}
			}
		}
	}

	.lang-menu {
		padding: 15px 5px;
	}
} 
.skin-blue .main-header {
	
	background-color: var(--blue);
	// background-color: var(--dark-green);

	.navbar, .logo {
		background: none !important;
	}
	
	.logo {
		border-right: 1px solid #37386d;
	}
}

.btn-delete {
	background-color: transparent;
	color: #d21e4a;
	text-align: center;
	display: block;
}

.btn-delete:hover {
	background-color: transparent;
	color: #d21e4a;
}

.breadcrumb a {
	color: #5f2182 !important;
}

.nav-tabs-custom > .nav-tabs > li.active {
	border-top-color: #d13c8c !important;
}

.content-wrapper .content .alert-info {
	background-color: #d13c8c !important;
	border-color: #d13c8c !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #d13c8c;
	border-color: #d13c8c;
}

.skin-blue .main-header .logo:hover, .skin-blue .main-header .navbar .sidebar-toggle:hover {
	background-color: #0073bd !important;
}

.skin-blue aside.main-sidebar {
	background-color: var(--grey);
}

.sidebar-menu > li > a:hover {
	color: var(--blue) !important;
}

.skin-blue .sidebar-menu > li:hover > a {
	background-color: var(--grey) !important;
	color: var(--blue) !important;
}

.skin-blue .sidebar-menu > li > a {
	color: var(--dark-grey);
	font-size: var(--base-font-size);
	font-weight: 900;
	line-height: 25px;
}

.sidebar-menu > li > a > .icon {
	width: 20px; height: 20px;
	display: inline-block;
	margin-right: 20px;
}
.sidebar-menu > li > a > .icon > img {
	max-width: 100%;
	max-height: 100%;
}

.sidebar-menu > li > a > span {
	vertical-align: middle;
}

.sidebar-menu li > a > .pull-right-container {
	margin-top: -5px;
}

.skin-blue .sidebar-menu > li.menu-open > a, .skin-blue .sidebar-menu > li > .treeview--menu {
	color: var(--dark-grey) !important;
	background-color: var(--grey) !important;
	list-style: none;
    padding: 0;
    margin: 0 1px;
	padding-left: 5px;
	overflow: hidden;
	transition: height .6s;
	
	> li {
		margin: 0;
	}
}

.skin-blue.sidebar-collapse .treeview--menu {
	position: absolute;
	left: 50px;
}

.skin-blue .sidebar-menu .treeview--menu > li > a {
	color: var(--dark-grey) !important;
	padding: 5px 5px 5px 15px;
    display: block;
	font-size: 14px;
	
	> .fa {
		width: 20px;
	}
}

.skin-blue .sidebar-menu .treeview--menu > li > a:hover {
	color: var(--blue) !important;
}

.content .article form {
	.col-md-10 .content .box-info {
		margin-top: 25px;
		.box-header {
			padding-top: 0;
			margin-bottom: 10px;
		}
	}
	
	.col-md-2 {
		margin: 15px 0 0 -15px;
		.box-info {
			.box-header {
				padding-top: 0;
				margin-bottom: 10px;
			}
			.box-footer .row {
				padding-top: 10px;
			}
		}
	}
}

#form_datePublish { line-height: normal }
#article-save .btn { margin: auto; display: block }

.wrapper, body {
	overflow-y: unset !important;
	overflow-x: unset !important;
}

.productList-img { width: 100px; height:auto; }

.content .productList.row {
	padding-left: 15px;
	margin-left: 15px;
	.btn { margin: 0 20px 15px 0; }
}

#produitForm, #produitForm .basics .box-body { padding-top: 0 }
#produitForm .box-info .box-header { padding-top: 0; margin-bottom: 10px }
.list-prodPhoto-item { width: 100px; flex: none; margin: 5px; }

#right-col {
	margin-top: 10px;
	.box-info .box-header { padding-top: 0; margin-bottom: 10px }
}

.list-prodPhoto { display: flex; flex-wrap: wrap }

.select2-container { width: 100% !important }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #d13c8c !important;
	border-color: #d13c8c !important;
}

.padl15 { padding-left: 15px }

input[type="number"].tva, input[type="number"].coupon, input[type="number"].ordre, input[type="number"].cat-parent, #right-col input[type="number"] {
	appearance: textfield !important;
	-webkit-appearance: textfield !important;
	-moz-appearance: textfield !important;
}

.question .col-md-6 { margin: 15px 15px 0 -15px; }
.resultat .col-md-4 { margin: 15px 0 0 -15px }
.question #form_save { margin: -15px 0 0 15px }
.resultat #form_save { margin: -30px 0 0 30px }

.block-plugins .btn { display: block !important }
.block-plugins .btn:hover { background-color: #d13c8c !important; color: white !important }



.text-center { text-align: center !important }
.thin { font-weight: 400 }
.uppercase { text-transform: uppercase }

.pull-right.joursFeries .custom-select-container {
	margin-top: 15px;
	min-width: 75px;

	.selected-text {
		background-color: var(--grey);
	}
}


.content .row.etablissements {
	overflow-x: auto;
	.col-md-12 { min-width: 1140px }
}
.content .row.professionnels {
	overflow-x: auto;
	.col-md-12 { min-width: 1190px }
	.box.tab-entete.professionnels tr.bottom {
		td { padding: 0 10px }
	}
}


.react-select-container input { padding: 0 !important }


//BOXES

.box {
	border-radius: 0 !important;
	border-bottom-right-radius: 15px !important;
	border: none !important;
	margin-bottom: 10px !important;
}


.box.info {
	background-color: var(--light-blue);
	padding: 20px 15px;

	p {
		margin-bottom: 0;
		color: var(--blue);
		font-size: var(--h2-font-size);
		font-weight: 700;
	}
	.small {
		font-size: var(--base-font-size);
	}
}

.box.info.secteurs form {
	display: flex;
	justify-content: space-between;

	button { display: block }
	.check {
		margin: 15px 0;
		input { margin-right: 10px }
	}
	.appliquer { display: flex; align-items: flex-end }
}

.box.info.reseauxProd {
	display: flex;
	align-items: center;
	
	.radio {
		margin-left: 50px;
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;

		input[type="radio"] { margin: 0 }

		.custom-select-container {
			margin-left: 25px;
		}
	}
	p, form { display: inline-block; margin-bottom: 0 }
	.btn-add { margin-left: auto; border: 1px solid var(--green) }
}

.box.info.etablissements, .box.info.professionnels {
	label {
		display: block;
	}
	.custom-select-container, input {
		display: block;
		margin-bottom: 20px;
		width: 100%;
	}
}


.box.alert {
	background-color: var(--yellow);
	color: var(--black);
	font-family: "Lato";
	margin-bottom: 20px !important;

	img { width: 34px; display: inline-block }
	h2 {
		font-weight: 700;
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 0 10px;
	}
	p {
		margin-top: 15px;
	}
}


.box.tab-entete {
	background-color: var(--grey);
	padding: 25px 15px;

	tbody { display: block }

	tr {
		border: none !important;
		display: block;
		height: auto;

		td {
			padding: 25px 10px;
			display: inline-block;
			
			> div {
				display: flex;
				align-items: flex-start;
			}

			p { text-align: center }
			p.lone { margin-bottom: 40px !important }

			input {
				width: 95%;
			}
		}
		td.center > div { justify-content: center }
	}

	p {
		font-size: var(--base-font-size);
		color: var(--black);
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 0 !important;

		.small {
			text-transform: none;
			font-weight: 300;
			opacity: 0.7;
			margin-bottom: -10px;
		}
	}

	img { width: 20px; margin-left: 15px }
}

.box.tab-entete.dashboard {
	td { width: 12.5%; height: 136px }
	p { margin-bottom: 20px !important }
}

.box.tab-entete.zoneGeo {
	p { margin: 20px 0 !important }
	.fa-trash { color: var(--dark-red); cursor: pointer }
	.fa-trash[disabled] {
		color: var(--dark-grey);
		cursor: not-allowed;
	}
}

.box.tab-entete.secteurs {
	padding: 0;
	td { width: 33% }
	p { margin: 0 }
	tr { display: flex; align-items: center}
	.btn-add { display: block; margin-left: auto }
}

.box.tab-entete.users {
	padding: 0;
	td { width: 15.5% }
	td.small { width: 6% }
	.text-center p { text-align: center; width: 100% }
	.btn-add { display: block; margin-left: auto }
	tr.bottom {
		td { padding-top: 0 }
	}
}

.box.tab-entete.coord {
	padding: 0;
	td { width: 10% }
	p { margin: 0 }

	td.nom { width: 17% }
	td.type { width: 12% }
	td.loc { width: 19% }
	td.modif { width: 12% }
}

.box.tab-entete.joursFeries {
	padding: 0;
	td { width: 33% }
	p { margin: 0 }

	.btn-add {
		margin-left: auto;
		display: block;
	}
}

.box.tab-entete.historique {
	padding: 0;
	td { width: 20% }
	td.action { width: 60% }
	p { margin: 0 }
	tr { display: flex; }
}

.box.tab-entete.reseaux {
	padding: 0;
	td { width: 50% }
	p { margin: 0 }
	.btn-add { display: block; margin-left: auto }
}
.box.tab-entete.reseauxProd {
	padding: 0;
	p { margin: 0 }
	.btn-add { display: block; margin-left: auto }

	&.defaut, &.pays, &.type, &.spe {
		td { width: 25% }
	}
}

.box.tab-entete.protype {
	padding: 0;
	p { margin-bottom: 20px !important }
}
#tab_1{
	table {
		tr {
			td {
				font-size: 11px;
				padding: 10px 10px;
			}
			&first-child{
				font-weight: bold;
			}
		}
	}
} 
.box.tab-entete.etablissements {
	padding: 0;
	p { margin: 0 }
	.btn-add, .btn-export { display: block; margin-left: auto }
	.react-select-container {
		width: 100%;
	}

	td.noTop{ padding-top: 0;}
	td.warn { width: 4% }
	td.ref { width: 10% }
	td.nom { width: 14%}
	td.type { width: 15% }
	td.loc {
		width: 20%;
		text-align: left !important;
		p {
			width: 100%;
			text-align: left !important;
		}
	}
	td.priv { width: 10% }
	td.stat { width: 10% }
	td.last { width: 15%; display: inline-flex; align-items: center }
}
.box.tab-entete.etabPDS, .box.tab-entete.etabHisto, .box.tab-entete.proHisto {
	padding: 0;
	td { width: 20% }
	td.first { width: 60% }
}

.box.tab-entete.professionnels {
	padding: 0;
	p { margin: 0 }
	.btn-add, .btn-export { display: block; margin-left: auto }
	tr{
		td { width: 14% }
		td.etab, td.stat { width: 8% }
		td.last { display: inline-flex; align-items: center }
		&:last-of-type {
			td{
				padding: 0px 10px 25px;
			}
		}
	}
}

.box.tab-entete.spe {
	padding: 0;
	p { margin: 0 }
	.btn-add { display: block; margin-left: auto }

	td { width: 28% }
	td.last { width: 15% }
	tr.bottom {
		td { padding: 0 10px }
	}
}

.box.tab-entete.pds, .box.tab-entete.logiciel, .box.tab-entete.typeEtab {
	padding: 0;
	p { margin: 0 }
	.btn-add { display: block; margin-left: auto }

	td { width: 75% }
	td.last { width: 25% }
}



//TABLE

.list {
	box-shadow: none !important;

	.list-row {
		font-weight: 700;
		font-size: var(--base-font-size);
		color: var(--black);

		&:hover {
			background-color: transparent;
		}

		td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 0;
			display: inline-block;
			border: none !important;
			vertical-align: middle !important;
		}

		td.planning {
			display: inline-flex;
			align-items: center;

			.tag {
				width: 110px;
				border-radius: 5px;
				padding: 3px 0 5px;
				text-align: center;

				p { margin-bottom: 0 }
			}
			.tag.valid {
				background-color: var(--light-green);
				color: var(--dark-green);
			}
			.tag.alert {
				background-color: var(--light-red);
				color: var(--dark-red);
			}
			.tag.warning {
				background-color: var(--light-yellow);
				color: var(--dark-yellow);
			}
		}

		td.icons {
			display: inline-flex;
    		justify-content: flex-end;
		}

		.icon-btn {
			padding: 10px;
			background-color: var(--grey);
			margin-left: 10px;
			cursor: pointer;
			display: inline-block;
			min-width: 40px;
			height: 40px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: var(--black);

			img {
				max-width: 20px;
				width: auto;
				max-height: 20px;
			}

			p {
				display: inline-block;
				margin-bottom: 0;
				margin-left: 5px;
			}
		}
		.icon-btn[disabled] {
			cursor: not-allowed;
		}

		td.valid { color: var(--green) }
		td.alert { color: var(--red) }
		td.warning { color: var(--yellow) }
	}
}

.list.dashboard {
	td { width: 12.5% }
}

.list.labo .list-row {
	display: flex;
	height: auto;
	align-items: center;
	justify-content: space-between;
}

.list.labo-prod td { width: 20% }

.list.secteurs {
	td { width: 33% }
}

.list.questions {
	.quest { width: 65% }
	.stat { width: 15% }
	.last { width: 20% }
}

.alert {
	border: none !important;
	margin-bottom: 0 !important;
}

.list.users {
	td { width: 15.5% }
	td.small { width: 6% }
}

.list.reseaux {
	td { width: 50% }
}
.list.reseauxProd {
	&.defaut, &.pays, &.type, &.spe {
		td { width: 25% }
	}
}

.list.coord {
	td { width: 10% }
	td.nom { width: 17% }
	td.type { width: 12% }
	td.loc { width: 19%; white-space: normal; }
	td.modif { width: 12% }

	.list-row td.planning {
		.tag {
			width: auto;
			padding: 0 10px;
		}
		&.photo .tag {
			border-radius: 50%;
			padding: 0 8px;
		}
		.tag.valid { cursor: pointer; }
	}
}

.list.joursFeries td { width: 33% }

.list.historique {
	.list-row td {
		white-space: normal;
		width: 20%
	}
	td.action { width: 60% }
}

.list.etablissements {
	td.warn { width: 4%; color: rgb(169, 68, 66) }
	td.ref { width: 10%; cursor: pointer }
	td.nom { width: 14%; cursor: pointer; white-space: normal }
	td.type { width: 15% }
	td.loc { width: 20%; white-space: normal; }
	td.priv { width: 10% }
	td.stat { width: 10%}
	td.last { width: 15% }

	.list-row td.planning.priv {
		.tag.prive {
			background-color: var(--light-blue);
			color: var(--blue)
		}
		.tag.inconnu {
			background-color: var(--grey);
			color: var(--dark-grey);
		}
		.tag {
			width: auto;
			margin: auto;
			padding: 0 10px;
		}
	}
}
.list.etabPDS, .list.etabHisto, .list.proHisto {
	td { width: 20% }
	td.first { width: 60% }
}
.list.etabHisto td.first {
	white-space: normal;
}

.list.professionnels {
	td { width: 14% }
	td.etab, td.stat { width: 8% }

	.list-row td.planning.priv {
		.tag.prive {
			background-color: var(--light-blue);
			color: var(--blue)
		}
		.tag.inconnu {
			background-color: var(--grey);
			color: var(--dark-grey);
		}
		.tag {
			width: auto;
			margin: auto;
			padding: 0 10px;
		}
	}

	.list-row td.planning {
		.tag {
			width: auto;
			padding: 0 10px;
		}
		&.etab .tag {
			border-radius: 50%;
			padding: 0 8px;
		}
		.tag.valid { cursor: pointer; }
	}
}

.list.spe {
	td { width: 28% }
	td.last { width: 15% }
}

.list.pds, .list.logiciel, .list.typeEtab {
	td { width: 75% }
	td.last { width: 25% }
}


.listZoneGeo {

	ul, li { list-style: none }
	ul { margin-bottom: 0 !important }
	li {
		cursor: pointer;
		border-bottom: 1px solid var(--dark-grey);
		padding-bottom: 15px;
		text-transform: uppercase;

		.small {
			color: var(--black);
			font-family: "Lato";
			font-size: 12px;
			font-weight: 300;
			padding-left: 12px;
		}
	}
	li.quart {
		ul, li { cursor: default }

		li:before {
			content: "•";
			border-color: transparent;
			top: 0.8em;
			left: -1.3em;
			color: var(--blue);
		}
	}
	.noQuart {
		cursor: default !important;
	}
	.noQuart:before {
		content: "•" !important;
		border-color: transparent !important;
		top: 1.2em !important;
		left: -1.3em !important;
		color: var(--blue);
	}
	.ville, .com, .quart { border: none; cursor: default }

	li[toggled="false"]:before {
		content: "";
		border-color: transparent var(--blue);
		border-style: solid;
		border-width: 0.35em 0 0.35em 0.45em;
		display: block;
		height: 0;
		width: 0;
		left: -1.1em;
		top: 1.9em;
		position: relative;
	}

	li[toggled="true"]:before {
		content: "";
		border: 6px solid transparent;
		border-color: var(--blue) transparent transparent transparent;
		display: block;
		height: 0;
		width: 0;
		left: -1.2em;
		top: 2.1em;
		position: relative;
	}

	.ville[active="true"], .com[active="true"], .quart[active="true"] { display: list-item }
	.ville[active="false"], .com[active="false"], .quart[active="false"] { display: none }

	.last { float: right; margin-top: 10px }

	.bouton {
		background-color: var(--grey);
		cursor: pointer;
		display: inline-block;
		padding: 1px 8px;
		margin-left: 5px;
		
		&.plus .fa { color: var(--green) }
		&.delete {
			.fa { color: var(--dark-red) }
			&[disabled] {
				.fa {color: var(--dark-grey);}
				cursor: not-allowed;
			}
		}
	}

	.invisible-input {
		color: var(--black) !important;
		display: inline-block;
		width: 200px;
	}
}


//BUTTONS

.btn-add {
	background-color: var(--light-green);
	display: inline-block;
	padding: 0 8px;
	border-bottom-right-radius: 8px;
	color: var(--dark-green);
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	border: none;

	&:hover {
		background-color: var(--dark-green);
		color: var(--light-green);
	}

	&:focus { outline: none }
}
.btn-add[disabled] {
	color: #fff;
	background-color: var(--light-green);
	cursor: not-allowed;
}

.btn-save {
	color: #fcffff;
	font-family: "Lato";
	font-size: var(--base-font-size);
	font-weight: 900;
	background-color: var(--blue);
	appearance: none;
	-webkit-appearance: none;
	border: none;
	padding: 10px 20px;

	img { margin-right: 3px }

	&:focus { outline: none }

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.btn-cancel {
	color: var(--black);
	font-family: "Lato";
	font-size: var(--base-font-size);
	font-weight: 700;
	background-color: #fff;
	appearance: none;
	-webkit-appearance: none;
	border: none;
	padding: 10px 20px;

	&:focus { outline: none }
}

.btn-info {
	color: var(--blue) !important;
	font-family: "Lato";
	font-size: var(--base-font-size);
	font-weight: 900;
	background-color: #fff !important;
	appearance: none;
	-webkit-appearance: none;
	border: none;
	padding: 10px 20px;
	margin-left: auto;
	display: block;

	&:focus { outline: none }
}

.btn-export {
	background-color: var(--light-yellow);
	display: inline-block;
	padding: 10px;
	border-bottom-right-radius: 8px;
	color: var(--dark-yellow);
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	border: none;

	img { margin-left: 0 !important; margin-right: 5px }

	&:hover {
		background-color: var(--dark-yellow);
		color: var(--light-yellow);
	}

	&:focus { outline: none }
}

.btn-delete {
	color: var(--dark-red);
	font-family: "Lato";
	font-size: var(--base-font-size);
	font-weight: 900;
	background-color: var(--light-red);
	appearance: none;
	-webkit-appearance: none;
	border: none;
	padding: 10px 20px;
	display: inline-block;
	margin-right: 15px;

	img { margin-right: 8px }

	&:focus { outline: none }
}

.btn-valid {
	color: var(--dark-green);
	font-family: "Lato";
	font-size: var(--base-font-size);
	font-weight: 900;
	background-color: var(--light-green);
	appearance: none;
	-webkit-appearance: none;
	border: none;
	padding: 10px 20px;

	img { margin-right: 4px }

	&:focus { outline: none }
}



// POPUP

.modal {
	z-index: 2000 !important;

	.modal-dialog {
		border-radius: 30px;
		overflow: hidden;
		
		.modal-content {
			padding: 15px;
			width: auto !important;

			.modal-header {
				h1 {
					font-family: "Lato";
					font-weight: 900;
					color: var(--black);
				}
			}

			.modal-body {
				label {
					display: block;
				}

				input {
					background-color: var(--grey);
				}
			}
		}
	}
}
.modal-backdrop { z-index: 0 !important }

#modalAddEditProd {
	input { margin-bottom: 15px }
	.custom-select-container {
		margin-bottom: 15px;
		.selected-text { background-color: var(--grey) }
	}

	.box.info p { font-size: var(--base-font-size) }

	.col-md-5 label { font-size: var(--base-font-size) }
	.col-md-2 {
		button {
			display: block;
			margin: 10px auto;
		}
		label { font-size: var(--base-font-size); text-align: center }
	}
}

#modalQuestions {
	.modal-dialog { width: 700px !important }
}

#modalAddEditQuestion {
	input[type="text"] {
		width: 100%;
		margin-bottom: 15px;
	}
	.radio { margin-right: 30px; display: inline-block }
	input[type="radio"] {
		margin-left: 0;
	}

	.ajoutRep {
		margin-top: 15px;
	}

	.custom-select-container .selected-text { background-color: var(--grey) }

	.list.questSpe {
		td.first { width: 80% }
		td.last { width: 20% }
	}
}

#modalAddEditSecteur {
	.modal-dialog { width: 700px !important }

	input[type="text"] {
		width: 100%;
		margin-bottom: 15px;
	}
	.custom-select-container .selected-text { background-color: var(--grey) }
	.check label { display: inline-block !important; margin-left: 10px }
	.col-md-6 { margin-bottom: 20px }

	.zoneGeoSecteur {
		margin-top: 20px;
	}
}

#modalAddJourFerie {
	input[type="text"] {
		width: 100%;
		margin-bottom: 15px;
	}
	.custom-select-container .selected-text { background-color: var(--grey) }
	label { margin-top: 15px }
}

#modalAddEditUser {
	.modal-dialog { width: 700px }
	input { margin-bottom: 15px }
	.custom-select-container {
		margin-bottom: 15px;
		.selected-text { background-color: var(--grey) }
	}
	input:not([type="checkbox"]) {
		width: 100%;
	}
	.box.info p { font-size: var(--base-font-size) }
	.check {
		margin: 10px 0;
		label { display: inline-block !important; margin-left: 10px }
	}
	.col-md-2 {
		button {
			display: block;
			margin: 10px auto;
		}
		label { font-size: var(--base-font-size); text-align: center }
	}
}

#modalAddEditReseau {
	input { width: 100% }
}

#modalAddEditResProd, #modalAddResConfigPays {
	.custom-select-container {
		margin-bottom: 15px;
		.selected-text { background-color: var(--grey) }
	}
}

#modalAddEditEtab {
	.modal-dialog { width: 900px }
	input:not([type="radio"]) {
		width: 100%;
		margin-bottom: 15px;
	}
	.custom-select-container .selected-text { background-color: var(--grey); margin-bottom: 15px }
	h2 {
		font-family: "Lato";
		font-weight: 600;
		color: var(--black);
	}
	.highlight, .highlight div, .highlighted .custom-select-container .selected-text {
		background-color: var(--light-yellow) !important;
		color: var(--dark-yellow) !important;
	}

	.select-react-option {
		p { margin-bottom: 2px }
	}
	.react-select-container {
		input { margin-bottom: 0 !important; padding: 7px 0 !important }
	}
	.zoneGeo-select {
		display: flex;
		align-items: center;

		.react-select-container {
			width: 100%
		}
	}

	button { margin-left: 15px }
	.btn-delete { margin-right: 0 }

	.searchZoneGeo {
		padding: 8px 12px 10px;
		background-color: var(--grey);
		margin-left: 10px;
		cursor: pointer;

		&:hover .fa {
			color: var(--blue);
		}
	}

	small { color: var(--dark-grey) }

	.radio-div {
		display: flex;
		align-items: center;
		margin: 10px -15px 15px;
	}
	.radio { margin: 0 30px; display: inline-block; margin-bottom: 5px !important }
	input[type="radio"] {
		margin-left: 0;
	}

	.row.comp {
		margin-top: 20px;
	}

	.spinner-loader {
		display: inline-block;
		margin-left: 10px;
	}
}

#modalAddEditPro, #modalAddEditProEtab {
	.modal-dialog { width: 1200px }
	input:not([type="radio"]) {
		width: 100%;
		margin-bottom: 15px;
	}
	.custom-select-container .selected-text { background-color: var(--grey); margin-bottom: 15px }
	.col-md-2 .custom-select-container { max-width: 100%; min-width: 100% }
	.highlight, .highlight div, .highlighted .custom-select-container .selected-text {
		background-color: var(--light-yellow) !important;
		color: var(--dark-yellow) !important;
	}

	.radio-div {
		display: flex;
		align-items: center;
		margin: 10px -15px 15px;

		.col-md-3 label {
			margin-bottom: 0px;
			line-height: 40px;
		}
	}
	.radio { margin-right: 30px; display: inline-block }
	input[type="radio"] {
		margin-left: 0;
	}

	.pdsReadOnly {
		.col-md-4, .col-md-8 { display: inline-block !important }
		label, p { margin-bottom: 1em; height: 20px }
	}

	.list.proSpe, .list.proLog {
		td { width: 30% }
		td.last { width: 10% }
	}
	.list.proEtab {
		td { width: 8% }
		p { width: 100%;
			overflow-wrap: break-word; }
	}

	.box.tab-entete.proSpe, .box.tab-entete.proLog {
		padding: 0;
		td { width: 30% }
		p { margin: 0 }
	}
	.box.tab-entete.proEtab {
		padding: 0;
		td { width: 8% }
		p { margin: 0;
			overflow-wrap: break-word; }
	}

	td.small { width: 5% !important }
	td.nameEtab {
		width: 22% !important;
		white-space: normal;
	}
	td.zg {
		width: 28% !important;
		white-space: normal;
	}
	td.type {
		width: 22% !important;
		white-space: normal;
	}
	td.last { width: 10% !important }
	td.stat { width: 8% !important }

	.btn-add { display: block; margin-left: auto }
	.speLog .btn-add { display: inline-block; margin-left: 10px }

	.tag.valid {
		background-color: var(--light-green);
		color: var(--dark-green);
	}
	.tag.warning {
		background-color: var(--light-yellow);
		color: var(--dark-yellow);
	}
	.tag.prive {
		background-color: var(--light-blue);
		color: var(--blue)
	}
	.tag.inconnu {
		background-color: var(--grey);
		color: var(--dark-grey);
	}

	.react-select-container {
		margin-bottom: 20px;

		input { margin-bottom: 0; padding: 7px 0 !important }

		.select-react-option.etab {
			> div {
				display: flex;
				align-items: center;

				.tag {
					border-radius: 5px;
					padding: 3px 10px 5px;
					text-align: center;
					margin-right: 10px;
				}
				.tag.valid {
					background-color: var(--light-green);
					color: var(--dark-green);
				}
				.tag.alert {
					background-color: var(--light-red);
					color: var(--dark-red);
				}
				.tag.warning {
					background-color: var(--light-yellow);
					color: var(--dark-yellow);
				}
				.tag.prive {
					background-color: var(--light-blue);
					color: var(--blue)
				}
				.tag.inconnu {
					background-color: var(--grey);
					color: var(--dark-grey);
				}
			}

			small { color: var(--dark-grey); opacity: 0.5 }
			p { margin-bottom: 0 }
		}
	}
}
#modalAddEditPro {
	button { margin-left: 15px }
	.btn-delete { margin-right: 0 }
	.spinner-loader { display: inline-block }
}

#modalAddEditSpe {
	input {
		margin-bottom: 15px;
	}
	.custom-select-container .selected-text { background-color: var(--grey); margin-bottom: 15px }
}

#modalAddEditLog, #modalAddEditTypeEtab {
	input { margin-bottom: 15px }
}

#modalChooseZoneGeo, #modalChooseZoneGeo2 {
	.custom-select-container .selected-text { background-color: var(--grey); margin-bottom: 15px }
}

#modalCoord {
	.modal-dialog { width: 1000px !important }
	.leaflet-container {
		height: 480px;
		width: 100%;
	}
}
.leaflet-container {
	height: 480px;
	width: 100%;
}

#modalLink {
	.react-select-container {
		margin-bottom: 300px;

		.select-react-option {
			> div {
				display: flex;
				align-items: center;

				.tag {
					border-radius: 5px;
					padding: 3px 10px 5px;
					text-align: center;
					margin-right: 10px;
				}
				.tag.valid {
					background-color: var(--light-green);
					color: var(--dark-green);
				}
				.tag.alert {
					background-color: var(--light-red);
					color: var(--dark-red);
				}
				.tag.warning {
					background-color: var(--light-yellow);
					color: var(--dark-yellow);
				}
				.tag.prive {
					background-color: var(--light-blue);
					color: var(--blue)
				}
				.tag.inconnu {
					background-color: var(--grey);
					color: var(--dark-grey);
				}
			}

			small { color: var(--dark-grey); opacity: 0.5 }
			p { margin-bottom: 0 }
		}
	}

	.spinner-loader {
		display: inline-block;
		margin-left: 10px;
	}
}

#modalAddZoneGeo {
	.select-react-option {
		p { margin-bottom: 2px }
	}
	.react-select-container {
		input { margin-bottom: 0 !important; padding: 7px 0 !important }
	}
	.zoneGeo-select {
		display: flex;
		align-items: center;

		.react-select-container {
			width: 100%
		}
	}

	.searchZoneGeo {
		padding: 8px 12px 10px;
		background-color: var(--grey);
		margin-left: 10px;
		cursor: pointer;

		&:hover .fa {
			color: var(--blue);
		}
	}

	label {
		margin-top: 20px
	}

	input[type="text"] {
		width: 50%
	}
}



//FORM

input {
	border: none !important;
	padding: 7px !important;
	color: var(--dark-grey) !important;
	font-size: var(--base-font-size) !important;
	font-weight: 400 !important;
	border-radius: 5px !important;

	&:focus {
		outline-color: var(--blue) !important;
	}

	&::placeholder {
		color: var(--dark-grey);
		opacity: 0.5;
	}

	&:disabled {
		cursor: not-allowed !important;
	}
}

.custom-select-container {
	display: inline-block;
	min-width: 200px;
	position: relative;
	cursor: pointer;

	.selected-text {
		padding: 10px 35px 10px 10px;
		border-radius: 5px;
		color: var(--dark-grey);
		font-family: "Lato";
		font-size: var(--small-font-size);
		font-weight: 400;
		background-color: #fff;
	}
	
	.selected-text::after {
		content: "";
		position: absolute;
		right: 10px;
		top: 18px;
		border: 5px solid transparent;
		border-color: var(--blue) transparent transparent transparent;
	}
	
	ul {
		margin: 0;
		padding: 0 5px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;
		border: 1px solid var(--grey);
		border-top: none;
	}
	
	.select-options {
		position: absolute;
		width: 100%;
		z-index: 10;
		margin: 0;
		max-height: 150px;
		overflow-y: auto;
	}
	
	li {
		list-style-type: none;
		padding: 5px;
		background-color: #fff;
		cursor: pointer;
		color: var(--dark-grey);
		font-family: "Lato";
		font-size: var(--small-font-size);
		font-weight: 400;
		border-radius: 8px;
	}
	
	li:hover {
		background-color: var(--blue);
		color: #ffffff;
	}

	&.disabled .selected-text { cursor: not-allowed }
}
.box.tab-entete.zoneGeo, .box.tab-entete.secteurs {
	.custom-select-container { text-transform: uppercase }
}



//TABS

.nav-tabs-custom {
	.nav-tabs {
		a.active {
			border-top: 1px solid var(--blue) !important;
		}
	}
}


//INFOBULLE

td.showOverflow {
	overflow: visible !important;
}
.infobulle {
	position: relative;

	&:hover::after, &:focus::after {
		content: attr(aria-label);
		position: absolute;
		top: -2.9em;
		left: 50%;
		transform: translateX(-50%); /* on centre horizontalement  */
		z-index: 10;
		white-space: nowrap;
		background-color: var(--light-blue);
		padding: 5px 10px;
		border-radius: 5px;
		color: var(--blue);
		transition: all .2s;
	}
}
[aria-label]:hover:before, [aria-label]:focus:before {
	content: "▼";
	position: absolute;
	top: -1.1em;
	left: 50%;
	transform: translateX(-50%); /* on centre horizontalement  */
	font-size: 22px;
	color: var(--light-blue);
}


//PAGES

.page-selector {
	margin-top: 20px;
	display: flex;
	justify-content: center;

	.page-btn {
		background-color: var(--light-blue);
		color: var(--black);
		padding: 10px 15px;
		cursor: pointer;
		margin: 0 3px;

		p {
			font-family: "Lato";
			font-size: 12px;
			margin: 0;
		}
	}
}


//CHARTS

.box.info.dashboard {
	.recharts-wrapper {
		margin: auto;
		margin-top: 15px;
	}
}

.info-box .progress .progress-bar {
	background: var(--green) !important;
}


//LOADING BAR

.loading-bar {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
	z-index: 1000;
}