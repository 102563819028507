@media screen and (max-width: 1700px) {
	.list.professionnels, .box.tab-entete.professionnels, .box.tab-entete.etablissements, .list.etablissements {
		td, p { font-size: 11px }
        input {
            font-size: 11px !important;
            height: 35px;
        }

		.list-row .icon-btn {
			min-width: 30px;
			height: 30px;
			img {
				max-width: 15px;
				max-height: 15px;
			}
		}
	}
    .box.tab-entete.professionnels, .box.tab-entete.etablissements {
        td.last {
			padding: 25px 5px 25px 0;
			.btn-add {
				font-size: 15px;
				padding: 0 5px 2px;
				border-bottom-right-radius: 5px;
				line-height: 18px;
                margin-left: 15px;
			}
			.btn-export {
				font-size: 11px;
				height: auto;
				width: auto;
				padding: 6px 18px 10px;
			}
		}

        img {
			width: 15px;
			margin-left: 12px;
			margin-top: 2px;
		}

        .custom-select-container {
            min-width: 100% !important;
            width: 100%;

            .selected-text {
                min-width: 100% !important;
                width: 100%;
            }
        }

        td > div {
            width: 100%;
            > div { width: 100% }
        }
    }
}

@media screen and (max-width: 1600px) {
    .content.coord {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.coord, .list.coord {
            min-width: 1100px;
        }
    }

    .content.joursFeries {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.joursFeries, .list.joursFeries {
            min-width: 500px;
        }
        .list-row .icon-btn {
			min-width: 30px;
			height: 30px;
			img {
				max-width: 15px;
				max-height: 15px;
			}
		}
    }

    .content.historique {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.historique, .list.historique {
            min-width: 800px;
        }
    }

    .content.spe {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.spe, .list.spe {
            min-width: 800px;
        }
        .list-row .icon-btn {
			min-width: 30px;
			height: 30px;
			img {
				max-width: 15px;
				max-height: 15px;
			}
		}

        input {
            font-size: 11px !important;
            height: 35px;
        }

        .custom-select-container {
            min-width: 100% !important;
            width: 100%;

            .selected-text {
                min-width: 100% !important;
                width: 100%;
            }
        }

        .box.tab-entete.spe td > div {
            width: 100%;
            > div { width: 100% }
        }
    }
}

@media screen and (max-width: 1550px) {
    .content.dashboard {
        .col-md-6, .col-md-7, .col-md-5 {
            width: 100%;
        }

        .first.row {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .content.zoneGeo {
        overflow-x: auto;
        .listZoneGeo {
            min-width: 650px;
        }
    }

    .content.users {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.users, .list.users {
            min-width: 1000px;
        }
        .list-row .icon-btn {
			min-width: 30px;
			height: 30px;
			img {
				max-width: 15px;
				max-height: 15px;
			}
		}
        .box.tab-entete.users {
            img {
                width: 15px !important;
                height: 15px !important;
                margin-left: 8px;
                margin-top: 0;
            }
        }
        input {
            font-size: 11px !important;
            height: 35px;
        }
    }
}

@media screen and (max-width: 767px) {
	.skin-blue .main-header {
		display: flex;
		flex-direction: column;

		.logo {
			width: auto;
			align-self: center;
			border: none;
			position: absolute;
			z-index: 100;
            border-right: none !important;
		}

		.navbar-nav .user-menu .user_a_dropdown {
			min-width: auto;
			.fa-user { margin: 0 10px }
		}
	}

	.skin-blue .main-sidebar {
		padding-top: 65px;
	}

    .content.logiciel, .content.typeEtab, .content.typePDS {
        overflow-x: auto;
        td, p { font-size: 11px !important }
        .box.tab-entete.logiciel, .list.logiciel, .box.tab-entete.typeEtab, .list.typeEtab, .box.tab-entete.pds, .list.pds {
            min-width: 300px;
        }
        .list-row .icon-btn {
			min-width: 30px;
			height: 30px;
			img {
				max-width: 15px;
				max-height: 15px;
			}
		}
    }
}