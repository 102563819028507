/* Table */
table { 
	width:100%; 
	box-shadow: 0 1px 1px rgba(0,0,0,.04); 
	background-color:white;
	border-radius: 5px; 
	display: table; 
	border-collapse: collapse; 
	border-spacing: 0;
}

table tr { 
	min-height:30px; 
	height:30px; 
	border-bottom: 1px solid rgba(0,0,0,0.12); 
	transition:0.2s;

	&:hover{
		background-color:#f5f5f5; 
		transition:0.2s;
	}
}

table tr th { 
	text-align:left; 
	padding: 15px 10px; 
	color:#666666; 
}

table tr td { 
	padding: 15px 10px; 
	font-size:14px; 
}

table tr td .fa-pencil { 
	color:#26a69a; 
}

table tr td .fa-trash { 
	color:#d21e4a; 
	margin-left:15px; 
}

table tr td .fa-eye { 
	color:#1e81d2; 
	margin-left:15px; 
}

table tr td img { 
	width:100px; 
	height:auto; 
	background-size:cover; 
}